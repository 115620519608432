import React, { useState } from 'react';
import { REACT_APP_EUROPE_DOMAIN_NAME_KEY, REACT_APP_PASSWORD_RESET_URLS } from '../../lib/constants';

export const ResetPasswordModal = props => {

    const checkURL = () => {
        let finalURL;
        if (window.location.href.includes("dev")) {
          if (window.location.href.includes("teleport"))
            finalURL = !window.location.origin
              .toLowerCase()
              .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
              ? REACT_APP_PASSWORD_RESET_URLS.india.dev.withTeleport
              : REACT_APP_PASSWORD_RESET_URLS.europe.dev.withTeleport;
          else
            finalURL = !window.location.origin
              .toLowerCase()
              .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
              ? REACT_APP_PASSWORD_RESET_URLS.india.dev.withoutTeleport
              : REACT_APP_PASSWORD_RESET_URLS.europe.dev.withoutTeleport;
        } else if (window.location.href.includes("qa")) {
          if (window.location.href.includes("teleport"))
            finalURL = !window.location.origin
              .toLowerCase()
              .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
              ? REACT_APP_PASSWORD_RESET_URLS.india.qa.withTeleport
              : REACT_APP_PASSWORD_RESET_URLS.europe.qa.withTeleport;
          else
            finalURL = !window.location.origin
              .toLowerCase()
              .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
              ? REACT_APP_PASSWORD_RESET_URLS.india.qa.withoutTeleport
              : REACT_APP_PASSWORD_RESET_URLS.europe.qa.withoutTeleport;
        } else {
          finalURL = !window.location.origin.toLowerCase().includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
            ? REACT_APP_PASSWORD_RESET_URLS.india.prod.withoutTeleport
            : REACT_APP_PASSWORD_RESET_URLS.europe.prod.withoutTeleport;
        }
        return finalURL;
    };


    return (
        <div>
            <a
                id={props.isNewLoginScreen ? "new-forgot-password-trigger" : "forgot-password-trigger"}
                style={props.isNewLoginScreen ? {color:'black'} : {color: '#0075A3'}}
                href={checkURL()}
            >
                {
                    props.isNewLoginScreen ? <>Forgot/Generate your password?</> : <>Generate/Change Password</>
                }
            </a>
        </div>
    );

}