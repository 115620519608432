import moment from "moment";

const addNewStateHelper = (freq, freqId, newState) => {
    for (var i in freq) {
        if (i == freqId) {
            freq[i].schedules = newState;
            break;
        }
    }
    return freq
}

const updateFreqHelper = (freq, freqId, newFreqTime) => {
    let tempFreq = freq
    for (var i in tempFreq) {
        if (i == freqId) {
            if (newFreqTime.isDate) {
                if (tempFreq[i].days_of_week)
                    delete tempFreq[i].days_of_week
                Object.assign(tempFreq[i], { ...newFreqTime })
            } else {
                if (tempFreq[i].date)
                    delete tempFreq[i].date
                Object.assign(tempFreq[i], { ...newFreqTime })
            }
        }
    }
    return tempFreq
}

const deleteFreqHelper = (freq, freqId) => {
    let tempFreq = [...freq.slice(0, freqId), ...freq.slice(freqId + 1)]
    return tempFreq
}

const deleteScheduleHelper = (schedule, scheduleId) => {
    delete schedule[scheduleId];
    return schedule
}

const bulkReducerInitialState = {
    selectedType: null,
    selectedRegions: null,
    selectedSites:null,
    selectedEquipmentsMapping: {},
    widgets: [],
    schedules: {},
    isPopOver: false,
    currEdit: {
        scheduleId: null,
        freqId: null,
    },
    validationConstraint: {},
    filterData: {},
    lastFreqId: null

}

const bulkReducer = (state = bulkReducerInitialState, action) => {
    switch (action.type) {
        case "BULK_RESET_STATE":
            state = bulkReducerInitialState
            break;
        case "BULK_CLEAR_NEW_SCHEDULES":
            state = {
                ...state, schedules: {}, currEdit: { scheduleId: null, freqId: null },
                filterData: {}, lastFreqId: null, isPopOver: false
            }
            break;
        case "BULK_UPDATE_POPOVER":
            state = {
                ...state, isPopOver: action.payload
            }
            break;
        case "BULK_UPDATE_META_INFO":
                state = {
                    ...state, 
                    selectedType: action.payload.equipmentType,
                    selectedRegions: action.payload.equipmentRegions,
                    selectedSites: action.payload.equipmentSites,
                    selectedEquipmentsMapping: action.payload.equipmentMapping,
                    widgets: action.payload.widgets,
                    validationConstraint: action.payload.validationConstraint
                }
                break;
            
        case "BULK_UPDATE_NEW_SCHEDULES": 
                state = { ...state, schedules: action.payload }
                break;
        case "BULK_ADD_NEW_SCHEDULES":
            state = {
                ...state, schedules: {
                    ...state.schedules,
                    [action.payload.key]: {
                        "start_date": null,
                        "expiry_date": null,
                        "frequency": [{
                            isDate: true,
                            date: moment().format("YYYY-MM-DD"),
                            schedules: null
                        }]
                    },
                },
                lastFreqId: null
            }
            break;
        case "BULK_DELETE_SCHEDULES":
            state = {
                ...state, schedules: {
                    ...deleteScheduleHelper(state.schedules,
                        [action.payload.key])
                }
            }
            break;
        case "BULK_ADD_NEW_FREQUENCY":
            state = {
                ...state, schedules: {
                    ...state.schedules,
                    [action.payload.key]: {
                        ...state.schedules[action.payload.key],
                        "frequency": [...state.schedules[action.payload.key].frequency,
                        {
                            isDate: true,
                            date: moment().format("YYYY-MM-DD"),
                            schedules: null
                        }]
                    }
                },
                lastFreqId: null
            }
            break;
        case "BULK_DELETE_FREQUENCY":
            state = {
                ...state, schedules: {
                    ...state.schedules,
                    [action.payload.key]: {
                        ...state.schedules[action.payload.key],
                        "frequency": [...deleteFreqHelper(state.schedules[action.payload.key].frequency,
                            action.payload.freqId)]
                    }
                }, lastFreqId: null

            }
            break;
        case "BULK_UPDATE_START_TIME":
            state = {
                ...state,
                schedules: {
                    ...state.schedules, [action.payload.key]: {
                        ...state.schedules[action.payload.key],
                        "start_date": action.payload.startDate
                    }
                }
            }
            break;
        case "BULK_UPDATE_END_TIME":
            state = {
                ...state,
                schedules: {
                    ...state.schedules, [action.payload.key]: {
                        ...state.schedules[action.payload.key],
                        "expiry_date": action.payload.endDate
                    }
                },
                validationConstraint: action.payload.validationConstraint
            }
            break;
        case "BULK_UPDATE_STATE":
            state = {
                ...state,
                schedules: {
                    ...state.schedules, [action.payload.key]: {
                        ...state.schedules[action.payload.key],
                        "frequency": [...addNewStateHelper(state.schedules[action.payload.key].frequency,
                            action.payload.freqId, action.payload.newState)]
                    }
                },
                currEdit: {
                    scheduleId: null,
                    freqId: null,
                    stateID: null
                }
            }
            break;
        case "BULK_UPDATE_FREQUENCY_TIME":
            state = {
                ...state,
                schedules: {
                    ...state.schedules, [action.payload.key]: {
                        ...state.schedules[action.payload.key],
                        "frequency": [...updateFreqHelper(state.schedules[action.payload.key].frequency,
                            action.payload.freqId, action.payload.newFreqTime)]
                    }
                },
                lastFreqId: action.payload.freqId,
                isPopOver: true
            }
            break;
        case "BULK_UPDATE_EDIT":
            state = {
                ...state,
                currEdit: { ...action.payload }
            }
            break;
        case "BULK_UPDATE_FILTER_DATA":
            state = {
                ...state,
                filterData: { ...state.filterData, ...action.payload }
            }
            break;
        default:
            break;
    }

    return state;
};


export default bulkReducer;
