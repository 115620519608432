
export function resetState() {
    return (dispatch) => {
        dispatch({
            type: "BULK_RESET_STATE"
        });
    }
}

export function clearNewSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_CLEAR_NEW_SCHEDULES"
        });
    }
}

export function updateMetaInfo(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_META_INFO",
            payload
        });
    }
}

export function updateNewSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_NEW_SCHEDULES",
            payload
        });
    }
}

export function addNewSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_ADD_NEW_SCHEDULES",
            payload
        });
    }
}

export function deleteSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_DELETE_SCHEDULES",
            payload
        });
    }
}

export function addNewFrequency(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_ADD_NEW_FREQUENCY",
            payload
        });
    }
}

export function updatePopOver(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_POPOVER",
            payload
        });
    }
}
export function updateFreqTime(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_FREQUENCY_TIME",
            payload
        });
    }
}

export function deleteFrequency(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_DELETE_FREQUENCY",
            payload
        });
    }
}

export function updateStartTime(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_START_TIME",
            payload
        });
    }
}

export function updateEndTime(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_END_TIME",
            payload
        });
    }
}

export function updateState(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_STATE",
            payload
        });
    }
}

export function updateEditStatus(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_EDIT",
            payload
        });
    }
}

export function updateFilterData(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "BULK_UPDATE_FILTER_DATA",
            payload
        });
    }
}