import React, { useState, useEffect } from 'react'
import { ArrowUpOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty'
import { addNewSchedules } from '../../actions/bulkActions';
import { SchedulesView } from './schedulesView';
import ArrowUp from "../../imgs/ArrowUp.svg"
import { useTranslation } from '../../context/translationContext';

export const BulkControl = () => {
    const { getTranslation } = useTranslation();
    const [schedulesData, setSchedulesData] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const bulkReducer = useSelector(state => state.bulkReducer)
    const dispatch = useDispatch();

    useEffect(() => {
        if (bulkReducer.selectedType) {
            if (!_isEmpty(bulkReducer.schedules)) {
                let allSchedules = []
                for (let key of Object.keys(bulkReducer.schedules))
                    allSchedules.push({ ...bulkReducer.schedules[key], timeFrameId: key })
                setSchedulesData([...allSchedules])
                if (allSchedules.length !== schedulesData.length)
                    setCurrentPage(allSchedules.length - 1)
            } else {
                setSchedulesData([])
                handleNew(true)
            }
        }
    }, [bulkReducer, bulkReducer.schedules, bulkReducer.selectedType])

    const handleNew = (isEmpty) => {
        if (isEmpty) {
            dispatch(addNewSchedules({ key: `time_frame1` }))
        } else {
            dispatch(addNewSchedules({ key: `time_frame${schedulesData.length + 1}` }))
        }
        // setCurrentPage(currentPage + 1)
    }

    const nextPage = () => {
        if (currentPage + 1 < schedulesData.length) {
            setCurrentPage(currentPage + 1)
        }
    }

    const prevPage = () => {
        if (currentPage - 1 >= 0) {
            setCurrentPage(currentPage - 1)
        }
    }

    const getView = () => {
        return (
            <div>
                {schedulesData.length !== 0 ?
                    schedulesData.length === 1 ? <SchedulesView isLast={true} parentData={schedulesData}
                        nextPage={nextPage} prevPage={prevPage} isFirst={true}
                        addNewSchedule={handleNew} config={schedulesData[0]} timeFrameId={0} /> :
                        <div style={{ height: '90vh', width: '100%', overflow: 'hidden' }} >
                            <div tabIndex="0" className="cs-page-scroll"
                                style={{ transform: `translate3d(0px,  ${currentPage * 100 * (currentPage > 0 ? -1 : 0)}%, 0px)` }} >
                                {
                                    schedulesData.map((schedulesConfig, id) =>
                                        <div style={{ height: '100%', width: '100%' }} >
                                            <SchedulesView parentData={schedulesData} config={schedulesConfig} isFirst={currentPage === 0}
                                                isLast={currentPage === schedulesData.length - 1}
                                                nextPage={nextPage} prevPage={prevPage}
                                                addNewSchedule={handleNew} timeFrameId={id} key={id} />
                                        </div>)
                                }
                            </div>
                        </div>
                    : null}
            </div>
        )
    }

    return (
        <>
            {bulkReducer.selectedType ? getView() : <div className="d ai-center jc-c cs-title"
                style={{ width: '100%', height: '80%', textAlign: 'center' }} >
                {getTranslation('Select type, region and site to View Bulk Controls')} <img src={ArrowUp} alt="arrow-up-icon" />
            </div>}
        </>
    )
}

